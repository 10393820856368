<script setup>
    import { ref, inject, onMounted } from 'vue';
    import ODelete from 'o365.vue.components.Action.Delete.vue';
    import MActionSheet from "mobile.vue.components.ActionSheet.vue";
    import MActionSheetBtn from "mobile.vue.components.ActionSheetBtn.vue";

    const props = defineProps(['row', 'index', 'groupName', 'lightVersion', 'largeVersion', 'documentViewer', 'imageEditor', 'connections', 'showFileNames', 'customButtons','geoLocationBtn','geoLocationConfig']);

    const attachmentClicked = (e) => {
        e.preventDefault();
        
        if (isImage(props.row.FileName)) {
            return true;
        } else {
            if (!props.documentViewer) {
                triggerDownload(props.row, true);
            }
        }
    }

    function deleteRow(row) {
        if (confirm($t("Are you sure you want to delete:") + "\n" + row.FileName)) {
            row.delete();
        }
    }

    const showImageEditing = (row) => {
        return props.imageEditor && isImage(row.FileName);
    }

    const getLink = inject('O365_AttachmentGetLink');
    const isImage = inject('O365_AttachmentIsImage');
    const getIconClasses = inject('O365_AttachmentGetIconClasses');
    const triggerDownload = inject('O365_AttachmentTriggerDownload');
    const openImageEditorByPrimKey = inject('O365_AttachmentOpenImageEditorByPrimKey');

    const showActionSheet = ref(false);
    
    const attachmentConnections = ref([]);

    onMounted(() => {
        if (props.connections) {
            props.connections.forEach(async connection => {
                var add = true;
                if (Object.hasOwn(connection, 'connectionCheck')) {
                    add = await connection.connectionCheck(props.row);
                }
                if (add) {
                    attachmentConnections.value.push(connection);
                }
            });
        }
    })
</script>

<template>
    <template v-if="lightVersion">
        <a class="text-truncate"
            style="min-width:0;"
            :href="`${getLink(row)}?updated=${row.Updated}`"
            data-spotlightitem
            :data-primkey="row.PrimKey"
            :data-groupname="groupName"
            @click="(e) => attachmentClicked(e)">
            <span>{{row.FileName}}</span>
        </a>

        <ODelete :row="row" class="btn btn-outline-secondary border-0 py-0 px-1">
            <i class="bi bi-x"></i>
        </ODelete>
    </template>

    <template v-else-if="largeVersion">
        <div class="p-1 d-flex align-items-center bg-white shadow-1 position-relative" style="border-radius: 0.5rem;">
            <template v-if="attachmentConnections.length">
                <div style="position: absolute; top: 1rem; right: 1rem; transform: translate(50%, -50%);">
                    <i class="bi bi-link-45deg text-muted"></i>
                </div>
            </template>

            <!-- touch area -->
            <a
                class="position-absolute inset-0"
                :href="`${getLink(row)}?updated=${row.Updated}`"
                data-spotlightitem
                :data-primkey="row.PrimKey"
                :data-groupname="groupName"
                @click="(e) => attachmentClicked(e)"
            ></a>

            <!-- icon -->
            <div class="flex-shrink-0 d-flex justify-content-center align-items-center" style="width: 4rem; height: 4rem;">
                <template v-if="isImage(row.FileName)">
                    <img
                        class="p-1 w-100 h-100"
                        style="object-fit: contain;"
                        :key="row.FileRef"
                        :src="`${getLink(row)}?updated=${row.Updated}&scale=thumbnail`"
                    />
                </template>
                <template v-else>
                    <i class="text-primary" :class="getIconClasses(row.FileName)" style="font-size: 2rem;"></i>
                </template>
            </div>

            <!-- body -->
            <div class="flex-grow-1 text-truncate" style="min-width: 0;">
                {{ row.FileName }}
            </div>

            <!-- dropdown -->
            <a
                class="flex-shrink-0 d-flex justify-content-center align-items-center text-black position-relative"
                style="width: 3rem; height: 3rem; text-decoration: none; z-index: 10;"
                @click="showActionSheet = true"
            >
                <i class="bi bi-three-dots-vertical"></i>
            </a>
        </div>

        <MActionSheet v-model="showActionSheet">
            <MActionSheetBtn icon="bi-download" :text="$t('Download')" @click="triggerDownload(row, true)" />
            <MActionSheetBtn icon="bi-trash" :text="$t('Delete')" @click="deleteRow(row)" />
            <template v-for="connection in attachmentConnections">
                <MActionSheetBtn :icon="'bi ' + connection.iconClass" :text="connection.title" @click="connection.onClick(row)" />
            </template>
        </MActionSheet>
    </template>

    <template v-else>
        <div style="position: relative;">
            <template v-if="isImage(row.FileName)">
                <a
                    class="d-block"
                    style="width: 6rem; height: 6rem;"
                    :href="`${getLink(row)}?updated=${row.Updated}`"
                    data-spotlightitem
                    :data-primkey="row.PrimKey"
                    :data-groupname="groupName"
                    @click="(e) => attachmentClicked(e)"
                >
                    <img
                        class="w-100 h-100 p-1 bg-white"
                        style="border-radius: 0.5rem; border: 1px solid var(--bs-gray-300); box-shadow: 2px 4px 8px rgba(0%, 0%, 0%, 5%); object-fit: contain;"
                        :key="row.FileRef"
                        :src="`${getLink(row)}?updated=${row.Updated}&scale=thumbnail`"
                    />
                </a>
            </template>
            <template v-else>
                <a
                    class="d-flex gap-1 bg-white text-truncate"
                    style="border: 1px solid var(--bs-gray-300); padding: 0.25rem 0.5rem; border-radius: 0.5rem; box-shadow: 2px 4px 8px rgba(0%, 0%, 0%, 5%);"
                    :href="`${getLink(row)}?updated=${row.Updated}`"
                    data-spotlightitem
                    :data-primkey="row.PrimKey"
                    :data-groupname="groupName"
                >
                    <div>
                        <i class="text-primary" :class="getIconClasses(row.FileName)" style="font-size: 1rem;"></i>
                    </div>
                    <div class="text-truncate text-black" style="font-size: 0.9em;">
                        {{ row.FileName }}
                    </div>
                </a>
            </template>
            <a
                class="d-flex justify-content-center align-items-center"
                style="position: absolute; top: 0; right: 0; transform: translate(50%, -50%); width: 3rem; height: 3rem;"
                @click="deleteRow(row)"
            >
                <div class="d-flex justify-content-center align-items-center" style="background-color: var(--bs-gray-300); border-radius: 999px; height: 1.25rem; aspect-ratio: 1;">
                    <i class="bi bi-x text-muted" style="font-size: 1.00rem;"></i>
                </div>
            </a>
        </div>
    </template>
</template>